import _extends from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import React, { useContext, useEffect, useRef } from "react";
import { emitCatalogueView, SNOWPLOW_SESSION_ID, SOURCE_TYPE_CONSTRUCTOR_BRAND_PAGE, SOURCE_TYPE_CONSTRUCTOR_CATEGORY_PAGE, SOURCE_TYPE_CONSTRUCTOR_SELLER_PAGE } from "@catchoftheday/analytics";
import { Breadcrumbs, ContentPlaceHolderPresetLineBlock } from "@catchoftheday/cg-components";
import { GlobalContextProvider, SponsoredDisclaimerModal } from "@catchoftheday/common-vendors";
import { convertProductCard, ProductGrid } from "@catchoftheday/product-components";
import { CataloguePageType } from "../../../consts/index";
import { CatalogueContext, CatalogueContextProvider } from "../../../contexts/CatalogueContext";
import { useBrowseQuery } from "../../../generated/graphql";
import { getBrowseValue } from "../../../utilities/api/getBrowseValue/getBrowseValue";
import { mapBrowseInput } from "../../../utilities/api/mapBrowseInput/mapBrowseInput";
import { BreadcrumbZone, FacetsZone, NoProductsZone, PageLayoutZone, PaginationZone, ProductsZone, ResultsZone } from "../../layouts/PageLayout/PageLayout";
import { CataloguePageMetaTags } from "../../pure/CataloguePageMetaTags/CataloguePageMetaTags";
import { CategoryPillNavigation } from "../../pure/CategoryPillNavigation/CategoryPillNavigation";
import { LoadingSpinner } from "../../pure/LoadingSpinner/LoadingSpinner";
import { NoResultsHeader } from "../../pure/NoResults/NoResultsHeader/NoResultsHeader";
import { Pagination } from "../../pure/Pagination/Pagination";
import { FacetContainer } from "../FacetContainer/FacetContainer";
import { HeaderContainer } from "../HeaderContainer/HeaderContainer";
import { NoResultsContainer } from "../NoResultsContainer/NoResultsContainer";
import { jsx as ___EmotionJSX } from "@emotion/react";
var sourceTypeMap = {
  brand: SOURCE_TYPE_CONSTRUCTOR_BRAND_PAGE,
  seller: SOURCE_TYPE_CONSTRUCTOR_SELLER_PAGE,
  category: SOURCE_TYPE_CONSTRUCTOR_CATEGORY_PAGE
};

var CatalogueContainerComponent = function CatalogueContainerComponent(_ref) {
  var host = _ref.host,
      customerUuid = _ref.customerUuid,
      pageType = _ref.pageType,
      queryParams = _ref.queryParams,
      browseValue = _ref.browseValue,
      isMobile = _ref.isMobile;
  var hasMounted = useRef(false);

  var _useContext = useContext(CatalogueContext),
      searchResponse = _useContext.searchResponse,
      updateSearchResponse = _useContext.updateSearchResponse,
      updateSearchLoading = _useContext.updateSearchLoading,
      safeSearchEnabled = _useContext.safeSearchEnabled,
      mobileFacetsOpen = _useContext.mobileFacetsOpen;

  var browseInput = mapBrowseInput(queryParams, browseValue, pageType, customerUuid);

  var _useBrowseQuery = useBrowseQuery({
    variables: {
      input: browseInput
    },
    onCompleted: function onCompleted(data) {
      updateSearchResponse(data.browse);
    },
    notifyOnNetworkStatusChange: true
  }),
      initialData = _useBrowseQuery.data,
      loading = _useBrowseQuery.loading,
      refetch = _useBrowseQuery.refetch;

  useEffect(function () {
    updateSearchLoading(loading);
  }, [loading, updateSearchLoading]);
  useEffect(function () {
    if (!hasMounted.current) {
      // Prevent refetch on initial render
      hasMounted.current = true;
      return;
    }

    refetch({
      input: mapBrowseInput(queryParams, browseValue, pageType, customerUuid)
    });
  }, [queryParams, refetch, customerUuid, browseValue, pageType]);

  var _ref2 = searchResponse || (initialData === null || initialData === void 0 ? void 0 : initialData.browse) || {},
      pageTitle = _ref2.pageTitle,
      results = _ref2.results,
      sortOptions = _ref2.sortOptions,
      facets = _ref2.facets,
      pagination = _ref2.pagination,
      categories = _ref2.categories,
      totalResults = _ref2.totalResults,
      metadata = _ref2.metadata;

  var breadcrumbsData = (initialData === null || initialData === void 0 ? void 0 : initialData.browse.breadcrumbs) || [];
  var breadcrumbs = breadcrumbsData.map(function (category, idx) {
    return {
      label: category.name,
      href: category.url,
      leaf: idx === breadcrumbsData.length - 1
    };
  });
  var categoryOptions = categories === null || categories === void 0 ? void 0 : categories.options;
  var isCategoryPage = pageType === CataloguePageType.CATEGORY;
  useEffect(function () {
    var emitView = function emitView() {
      if (metadata) {
        emitCatalogueView(metadata.snowplowContexts);
      }
    };

    var checkSP = function checkSP(event) {
      if (event.key === SNOWPLOW_SESSION_ID) {
        emitView();
      }
    };

    emitView();
    window.addEventListener("storage", checkSP);
    return function () {
      return window.removeEventListener("storage", checkSP);
    };
  }, [metadata]);

  if (!loading && !(initialData !== null && initialData !== void 0 && initialData.browse.results.length) && !(initialData !== null && initialData !== void 0 && initialData.browse.facets.appliedFacets.length)) {
    return ___EmotionJSX(NoResultsContainer, {
      host: host
    });
  }

  var products = results === null || results === void 0 ? void 0 : results.map(function (result) {
    return convertProductCard(_objectSpread(_objectSpread({}, result.product), {}, {
      sourceType: sourceTypeMap[pageType] ? parseInt("".concat(sourceTypeMap[pageType]), 10) : undefined,
      sourceId: browseValue,
      linkToOffer: pageType === CataloguePageType.SELLER
    }), result.product.selectedOffer);
  });
  var pageContainsAdultProducts = results === null || results === void 0 ? void 0 : results.some(function (result) {
    return result.product.adult;
  });
  return ___EmotionJSX(PageLayoutZone, _extends({}, pageType === CataloguePageType.SEARCH ? {
    "data-cnstrc-search": true
  } : {
    "data-cnstrc-browse": true
  }, {
    "data-cnstrc-num-results": totalResults
  }), ___EmotionJSX(CataloguePageMetaTags, {
    host: host,
    pageType: pageType,
    metaTitle: metadata === null || metadata === void 0 ? void 0 : metadata.metaTitle,
    metaDescription: metadata === null || metadata === void 0 ? void 0 : metadata.metaDescription
  }), loading && ___EmotionJSX(LoadingSpinner, null), ___EmotionJSX(HeaderContainer, {
    pageTitle: pageTitle,
    sortOptions: sortOptions,
    totalFacetsApplied: facets === null || facets === void 0 ? void 0 : facets.appliedFacets.length,
    breadcrumbs: ___EmotionJSX(React.Fragment, null, isCategoryPage && ___EmotionJSX(React.Fragment, null, loading && ___EmotionJSX(BreadcrumbZone, null, ___EmotionJSX(ContentPlaceHolderPresetLineBlock, {
      width: "100%",
      height: "24px"
    })), !loading && breadcrumbs.length > 0 && ___EmotionJSX(BreadcrumbZone, null, ___EmotionJSX(Breadcrumbs, {
      breadcrumbs: breadcrumbs,
      useNextLinks: true,
      listProps: {
        mb: 0
      }
    }))))
  }), isCategoryPage && ___EmotionJSX(CategoryPillNavigation, {
    categories: categoryOptions || []
  }), ___EmotionJSX(ResultsZone, null, ___EmotionJSX(FacetsZone, {
    mobileFacetsOpen: mobileFacetsOpen
  }, facets && ___EmotionJSX(FacetContainer, {
    facets: facets,
    categories: categories,
    showSafeSearch: pageContainsAdultProducts,
    totalResults: totalResults,
    pageType: pageType
  })), ___EmotionJSX(ProductsZone, null, products && products.length > 0 && ___EmotionJSX(ProductGrid, {
    productItems: products,
    displayAdultBlur: safeSearchEnabled,
    placeholderProps: {
      minHeight: "427px"
    }
  }), !(products !== null && products !== void 0 && products.length) && !loading && ___EmotionJSX(NoProductsZone, null, ___EmotionJSX(NoResultsHeader, {
    hasAppliedFilters: !!(facets !== null && facets !== void 0 && facets.appliedFacets.length)
  })), ___EmotionJSX(PaginationZone, null, pagination && ___EmotionJSX(Pagination, {
    pagination: pagination,
    isMobile: isMobile
  })))));
};

export var CatalogueBrowseContainer = function CatalogueBrowseContainer(props) {
  var browseValue = getBrowseValue({
    pageType: props.pageType,
    pathParams: props.queryParams
  });
  return ___EmotionJSX(CatalogueContextProvider, null, ___EmotionJSX(GlobalContextProvider, null, ___EmotionJSX(SponsoredDisclaimerModal, null), browseValue ? ___EmotionJSX(CatalogueContainerComponent, _extends({}, props, {
    browseValue: browseValue
  })) : ___EmotionJSX(NoResultsContainer, {
    host: props.host
  })));
};